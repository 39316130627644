import axios from "axios";

export default {
    async getSondaggi() {
        const data = new FormData();
        data.append("limit", "50");
        data.append("offset", "0");
        data.append("orderby", "sondaggi_creation_date");
        data.append("orderdir", "desc");

        const response = await axios.post(`rest/v1/search/sondaggi`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data.data;
    },

    async getSondaggioDomande(sondaggioID: string) {
        const data = new FormData();
        data.append("where[sondaggi_domande_sondaggio_id]", sondaggioID);
        data.append("orderby", "sondaggi_domande_ordine");
        data.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/sondaggi_domande`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data.data;
    },

    async getDomandeRisposte(domandaId: string) {
        const data = new FormData();
        data.append("where[sondaggi_domande_risposte_domanda_id]", domandaId);
        data.append("orderby", "sondaggi_domande_risposte_id");
        data.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/sondaggi_domande_risposte`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data.data;
    },

    /* async createSondaggio(iuserId: string) {
        const data = new FormData();
        data.append("where[sondaggi_domande_risposte_domanda_id]", domandaId);
        data.append("orderby", "sondaggi_domande_risposte_id");
        data.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/sondaggi_domande_risposte`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data.data;
    }, */
};
